<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/assists/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-6></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/Assits.png')" />
    </v-container>
    <Informacion />
    <v-container grid-list-xl>
        <v-card outlined>
            <v-container>
                <p class="azul font-22 text-center">
                    Completa el formulario y obten tu Proteccción Assist
                </p>
                <v-form v-model="valid" ref="formClienteNuevo" v-on:submit.prevent="">
                    <v-layout wrap>
                        <v-flex xs12 md4>
                            <v-text-field v-model="cliente.parentesco" dense label="¿Para quién quieres contratar este servicio?" placeholder="Por ejemplo: Para mi, Para mi primo, Para mi mama, etc." counter="80" outlined :rules="reglas.campo"></v-text-field>
                            <v-text-field v-model="cliente.nombres" color="#0C55A6" :rules="reglas.campo" counter="80" label="Nombre(s)" outlined dense></v-text-field>
                            <v-text-field v-model="cliente.apPat" color="#0C55A6" :rules="reglas.campo" counter="80" label="Apellido paterno" outlined dense></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field v-model="cliente.apMat" color="#0C55A6" :rules="reglas.campo" counter="80" label="Apellido materno" outlined dense></v-text-field>
                            <v-text-field v-model="cliente.correo" color="#0C55A6" :rules="reglas.correo" counter="150" label="Correo" outlined dense></v-text-field>
                            <v-text-field v-model="cliente.rfc" color="#0C55A6" :rules="reglas.rfc" counter="13" label="RFC" outlined dense></v-text-field>
                        </v-flex>
                        <v-flex xs12 md4>
                            <v-text-field v-model="cliente.celular" color="#0C55A6" v-mask="'##########'" :rules="reglas.telefono" counter="10" label="Teléfono" outlined dense></v-text-field>
                            <v-select v-model="cliente.genero" :items="['Masculino', 'Femenino']" dense label="Género" outlined :rules="reglas.requerido"></v-select>
                            <v-text-field v-model="cliente.fechaNacimiento" v-mask="'####-##-##'" placeholder="aaaa-mm-dd" color="#0C55A6" :rules="reglas.requerido" label="Fecha de nacimiento" outlined dense></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-form>
                <v-layout>
                    <v-flex xs12 md5 class="mx-auto">
                        <v-btn @click="generaReferenciaPago()" depressed small dark block class="btnNoMayuscula" color="#0C55A6">
                            CONTRATA AQUÍ POR $2 (dos pesos) AL MES
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-container>
    <Footer programa="Assist" />
</div>
</template>

<script>
import Footer from "@/components/FooterPrograma.vue";
import Informacion from "@/components/assist/Informacion.vue";

import axios from "axios";
import {
    backend
} from "@/back/global.js";

export default {
    name: "Compraassit",
    components: {
        Footer,
        Informacion
    },
    data: () => ({
        valid: true,
        listaParentescos: [],
        cliente: {
            parentesco: "",
            nombres: "",
            apPat: "",
            apMat: "",
            celular: "",
            correo: "",
            fechaNacimiento: "",
            rfc: "",
            genero: ""
        },
        reglas: {
            rfc: [
                value => !!value || "Campo requerido.",
                value => value.length <= 13 || "El campo no puede ser mayor a 13 caracteres."
            ],
            requerido: [
                value => !!value || "Campo requerido.",
            ],
            campo: [
                value => !!value || "Campo requerido.",
                value => value.length <= 80 || "El campo no puede ser mayor a 80 caracteres."
            ],
            correo: [
                value => !!value || "Campo requerido.",
                value => value.length <= 150 || "El campo no puede ser mayor a 150 caracteres."
            ],
            telefono: [
                value => !!value || "Campo requerido."
            ]
        }
    }),
    methods: {
        catalogoParentescos() {
            this.$store.state.overlay = true;
            axios({
                    method: "get",
                    url: backend.url + "assit/catalogoParentesco",
                    headers: backend.headers
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            token.data.parentescos.forEach(element => {
                                this.listaParentescos.push(element.nombreParentesco);
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos obtener el listado de parentescos: ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        generaReferenciaPago() {
            if (!this.$refs.formClienteNuevo.validate()) {
                return false;
            }
            this.$store.state.overlay = true;
            axios({
                    method: "post",
                    url: backend.url + "assit/registraNuevoCliente",
                    headers: backend.headers,
                    data: {
                        parentesco: this.cliente.parentesco,
                        nombres: this.cliente.nombres,
                        apPat: this.cliente.apPat,
                        apMat: this.cliente.apMat,
                        celular: this.cliente.celular,
                        correo: this.cliente.correo,
                        fechaNacimiento: this.cliente.fechaNacimiento,
                        rfc: this.cliente.rfc,
                        genero: this.cliente.genero
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            this.ventanaPago(token.data.referencia, token.data.producto.costo, token.data.producto.nombreProducto, token.data.cliente);
                            break;
                        case 0:
                            this.$snotify.error(token.msg, "Aviso", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos comenzar tu proceso de pago: ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        },
        ventanaPago(referencia, costo, producto, cliente) {
            var pago = {
                total: costo,
                reference: referencia,
                cust: {
                    fname: cliente.nombres,
                    mname: cliente.apPat,
                    lname: cliente.apMat,
                    email: cliente.correo,
                    phone: "52" + cliente.celular
                },
                items: [{
                    name: producto,
                    qty: 1,
                    desc: producto,
                    unitPrice: costo
                }],
                recurring: {
                    interval: "month",
                    total: costo
                },
            };
            var SW = new BwGateway({
                sandbox: backend.sandbox,
                user: backend.usuBw,
                title: "Protección Chedraui",
                concept: producto,
                currency: 'MXN',
                time: 900,
                paymentOptions: 'visa,mastercard',
                reviewOrder: true,
                showShipping: false,
                notifyUrl: backend.urlNotify,
                successPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onSuccess: function (data) {},
                pendingPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onPending: function (data) {},
                challengePage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onChallenge: function (data) {},
                errorPage: window.location.origin + '/' + ((backend.urlProyecto != '') ? backend.urlProyecto + 'pago' : 'pago'),
                onError: function (data) {},
                onCancel: function (data) {}
            });
            SW.pay(pago);
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerAssistMovil';
                case 'sm':
                    return 'BannerAssistMovil';
                case 'md':
                    return 'BannerAssist';
                case 'lg':
                    return 'BannerAssist';
                case 'xl':
                    return 'BannerAssist';
            }
        }
    }
}
</script>
